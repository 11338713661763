import { select } from "d3-selection"
import { alreadyDrawn, Renderer, Rendition } from "../renderer"
import { Widget } from "../widget"

interface Publication {
  id: string
  internal: { icon: string; formattedDate: string; htmlTitle: string }
  html: string
}

interface Data {
  publications: Publication[]
}

export const pubTable: Renderer = function (
  widget: Widget,
  data: Data
): Rendition {
  const drawnPubs = select(widget.container)
    .selectAll(".publication")
    .data(data.publications as Publication[])
    .enter()
    .append("div")
    .attr("class", "pub")
    .attr("data-publication-id", (pub) => pub["id"])

  const header = drawnPubs.append("h2")

  header
    .append("span")
    .attr("class", "icon")
    .text((pub) => pub.internal.icon)

  header
    .append("span")
    .attr("class", "date")
    .text((pub) => pub.internal.formattedDate)

  header
    .append("a")
    .attr("href", "#")
    .html((pub) => pub.internal.htmlTitle)
    .on("click", function (event: Event) {
      const node = event.target as HTMLElement
      node?.parentElement?.parentElement?.classList?.toggle("expanded")
      event.preventDefault()
    })

  drawnPubs
    .append("div")
    .attr("class", "text")
    .html((pub) => pub.html)

  widget.markup(drawnPubs)

  return alreadyDrawn
}
