import { select, Selection, BaseType } from "d3-selection"
import { line, curveLinear } from "d3-shape"
import { Widget } from "./widget"
import * as Dom from "../util/dom"
import { NorthData } from "../viz-base"

export interface Node {
  x: number
  y: number
  id: number
  desc: string
  children?: Node[]
  root: boolean
  old?: boolean
  warning?: string
  distance?: number
  url?: string
  text: string
  clickable?: boolean
  icon: string
  type: string
  query: unknown
}

export interface Link {
  source: Node
  target: Node
}

export const bigNodeRadius = 20
export const smallNodeRadius = 8

export function createArrowPath(arrowSize: number): string {
  const arrowRatio = 0.5
  const d = [
    { x: 0, y: 0 },
    { x: 1, y: arrowRatio },
    { x: 1, y: -arrowRatio },
    { x: 0, y: 0 },
  ]
  const lineFunction = line<{ x: number; y: number }>()
    .x(function (d) {
      return arrowSize * d.x
    })
    .y(function (d) {
      return arrowSize * d.y
    })
    .curve(curveLinear)
  return lineFunction(d) as string
}

// in SVG, texts don't have background. Thus, we add a "rect" element to serve as the background
// this function keeps the position and width of text and background in sync
export function syncTextBackground<
  GElement extends BaseType,
  Datum,
  PElement extends BaseType,
>(
  elements: Selection<GElement, Datum, PElement, unknown>,
  textSelector: string,
  bgSelector: string,
  marginX: number,
  marginY: number,
  maxWidth: number,
  cornerRadius: number = 0
) {
  elements.each(function () {
    const parent = select(this)
    const text = parent.select<SVGTextElement>(textSelector).node()
    const bg = parent.select<SVGRectElement>(bgSelector).node()
    if (text && bg && text.textContent) {
      const bbox = text.getBBox()
      const dx = Number(parent.attr("data-dx"))
      if (dx != 0) {
        const oldX = bbox.x
        const minX = -dx + marginX
        const maxX = maxWidth - marginX - bbox.width - dx
        if (oldX < minX) {
          bbox.x = minX
          select(text).attr("x", minX - oldX)
        } else if (oldX > maxX) {
          bbox.x = maxX
          select(text).attr("x", maxX - oldX)
        }
      }
      select(bg)
        .attr("width", bbox.width + marginX * 2)
        .attr("height", bbox.height + marginY * 2)
        .attr("x", bbox.x - marginX)
        .attr("y", bbox.y - marginY)
        .attr("rx", cornerRadius)
        .attr("ry", cornerRadius)
    }
  })
}

function moveToFront(elements: Element[]) {
  // in SVG, the last children are always in front
  // thus, we have to move the elements to the end in their parent's children list
  // this is only possible by re-appending them.
  elements.reverse()
  const uniqueElements: Element[] = []
  for (let element of elements) {
    if (uniqueElements.indexOf(element) < 0) {
      uniqueElements.push(element)
    }
  }
  uniqueElements.reverse()
  const size = uniqueElements.length
  if (size > 0 && !Dom.isIE()) {
    const first = uniqueElements[0]
    const parent = first.parentNode
    const children = parent == null ? [] : parent.childNodes
    // move only if not already there to avoid flickering
    let alreadyThere = true
    for (let i = 0; i < size; ++i) {
      if (children[children.length - size + i] != uniqueElements[i]) {
        alreadyThere = false
        break
      }
    }
    if (!alreadyThere) {
      for (let element of uniqueElements) {
        const parent = element.parentNode
        parent?.appendChild(element)
      }
    }
  }
}

export function drawNodes<
  GElement extends BaseType,
  Datum,
  PElement extends BaseType,
>(
  widget: Widget,
  svg: Selection<GElement, Datum, PElement, unknown>,
  nodes: Node[],
  rootId: number | undefined,
  denseLeaves: boolean
) {
  const maxWidth = widget.container.getBoundingClientRect().width

  function selectNode(id: number) {
    return svg.select<Element>(".node[data-id='" + id + "']")
  }

  function selectRootNode() {
    return svg.select<Element>("[data-root]")
  }

  let restoreDefaultOrder: number /* timeout handler */

  function highlight(node: Node) {
    const nodeId = node.id
    const highlightMode = nodeId == rootId ? "root" : "node"

    // highlight node
    const nodeEl = selectNode(nodeId).attr("data-highlight", highlightMode)
    nodeEl.select<Element>(".text").text(node.desc)
    syncTextBackground(
      nodeEl,
      ".text",
      ".text-bg",
      denseLeaves ? 2 : 5,
      2,
      maxWidth
    )

    // highlight links
    const linkEls = svg
      .selectAll<
        Element,
        Link
      >("[data-source-id='" + nodeId + "'],[data-target-id='" + nodeId + "']")
      .attr("data-highlight", highlightMode)
    syncTextBackground(linkEls, ".desc", ".desc-bg", 3, 2, maxWidth)

    // bring highlighted stuff to front
    window.clearTimeout(restoreDefaultOrder)
    const toBeMoved: Element[] = []
    linkEls.each(function (link) {
      toBeMoved.push(this)
      function moveOtherEndToFront(id: number) {
        if (id != nodeId) {
          toBeMoved.push(selectNode(id).node() as Element)
        }
      }
      moveOtherEndToFront(link.source.id)
      moveOtherEndToFront(link.target.id)
    })
    toBeMoved.push(nodeEl.node() as Element)
    moveToFront(toBeMoved)
  } // end highlight

  function arrangeInDefaultOrder() {
    const toBeMoved: Element[] = []
    svg.select<Element>(".node").each(function () {
      toBeMoved.push(this)
    })
    toBeMoved.push(selectRootNode().node() as Element)
    moveToFront(toBeMoved)
  }

  function unhighlight(node: Node) {
    svg.selectAll("[data-highlight]").attr("data-highlight", "false")
    const nodeEl = svg.select(".node[data-id='" + node.id + "']")
    nodeEl.select(".text").text(node.text).attr("x", 0)

    restoreDefaultOrder = window.setTimeout(arrangeInDefaultOrder, 1000)
  }

  let d3NodesContainer = svg.select<SVGGElement>("g.nodes")

  if (d3NodesContainer.empty()) {
    d3NodesContainer = svg.append("g").attr("class", "nodes")
  }

  d3NodesContainer.style("pointer-events", "all")
  const d3Nodes = d3NodesContainer.selectAll(".node").data(nodes)

  const clickable = (node: Node) => node.clickable || !node.root
  // I think all code related to d3NewNodes  can be deleted safely
  // because the rendering is now done on the server
  const d3NewNodes = d3Nodes
    .enter()
    .append<SVGAElement>("svg:a")
    .attr("class", "node")
    .attr("data-leaf", (node) => !node.children || node.children.length == 0)
    .attr("data-id", (node) => node.id)
    .attr("data-root", (node) => node.root)
    .attr("data-old", (node) => node.old ?? null)
    .attr("data-warning", (node) => node.warning ?? null)
    .attr("data-d", (node) => node.distance ?? null)
    .attr("xlink:href", function (node) {
      return node.url || "" // clickable(node) ? widget.getLinkifier()(this) : null
    })
    .attr("data-clickable", (node) =>
      clickable(node) && widget.getClickHandler(node.type) ? true : null
    )
    .on("click", function (event: Event, node) {
      if (clickable(node)) {
        if (widget.invokeClickHandler(node.query, node.type)) {
          event.preventDefault()
        }
      }
    })

  Dom.onEvent(
    "mouseover",
    d3NodesContainer.node() as Element,
    d3NewNodes,
    highlight
  )
  Dom.onEvent(
    "mouseout",
    d3NodesContainer.node() as Element,
    d3NewNodes,
    unhighlight
  )

  d3NewNodes.append("circle").attr("r", bigNodeRadius)

  d3NewNodes
    .append("text")
    .attr("class", "icon")
    .text((node) => node.icon)

  d3NewNodes.append("rect").attr("class", "text-bg")

  d3NewNodes
    .append("text")
    .attr("class", "text")
    .text((node) => node.text)

  d3NewNodes
    .filter("[data-warning]")
    .append("circle")
    .attr("r", 7)
    .attr("data-warn", true)
    .attr("transform", "translate(20, -20)")
  d3NewNodes
    .filter("[data-warning]")
    .append("text")
    .attr("r", 8)
    .attr("class", "icon")
    .text("\uf071")
    .attr("data-warn", true)
    .append("title")
    .text((node) => node.warning ?? "")

  arrangeInDefaultOrder()

  return d3NewNodes
}

export function enrichNodes<
  GElement extends BaseType,
  Datum,
  PElement extends BaseType,
>(
  widget: Widget,
  svg: Selection<GElement, Datum, PElement, unknown>,
  //nodes: Node[]
  //rootId: number | undefined,
  denseLeaves: boolean
) {
  const maxWidth = widget.container.getBoundingClientRect().width

  function selectNode(id: string) {
    return svg.select<Element>(".node[data-id='" + id + "']")
  }

  function selectRootNode() {
    return svg.select<Element>("[data-root]")
  }

  let restoreDefaultOrder: number /* timeout handler */

  function highlight(node: Element) {
    const nodeId = node.getAttribute("data-id")
    const highlightMode =
      node.getAttribute("data-root") == "true" ? "root" : "node"

    // highlight node
    node.setAttribute("data-highlight", highlightMode)
    var textNode = node.querySelector(".text")
    if (textNode) {
      textNode.textContent = node.getAttribute("data-description")
    }

    syncTextBackground(
      select(node),
      ".text",
      ".text-bg",
      denseLeaves ? 2 : 5,
      2,
      maxWidth
    )

    // highlight links
    const linkEls = svg
      .selectAll<
        Element,
        Link
      >(".link[data-source-id='" + nodeId + "'],.link[data-target-id='" + nodeId + "']")
      .attr("data-highlight", highlightMode)
    syncTextBackground(linkEls, ".desc", ".desc-bg", 3, 2, maxWidth)

    // bring highlighted stuff to front
    window.clearTimeout(restoreDefaultOrder)
    const toBeMoved: Element[] = []
    linkEls.each(function (link) {
      var linkEl = this
      toBeMoved.push(linkEl)
      function moveOtherEndToFront(id: string) {
        if (id != nodeId) {
          toBeMoved.push(selectNode(id).node() as Element)
        }
      }
      moveOtherEndToFront(linkEl.getAttribute("data-source-id") || "")
      moveOtherEndToFront(linkEl.getAttribute("data-target-id") || "")
    })
    toBeMoved.push(node)
    moveToFront(toBeMoved)
  }

  function arrangeInDefaultOrder() {
    const toBeMoved: Element[] = []
    svg.select<Element>(".node").each(function () {
      toBeMoved.push(this)
    })
    toBeMoved.push(selectRootNode().node() as Element)
    moveToFront(toBeMoved)
  }

  function unhighlight(node: Element) {
    svg.selectAll("[data-highlight]").attr("data-highlight", "false")
    const nodeId = node.getAttribute("data-id")
    svg
      .select(".node[data-id='" + nodeId + "']")
      .select(".text")
      .text(node.getAttribute("data-text") || "")
      .attr("x", 0)

    restoreDefaultOrder = window.setTimeout(arrangeInDefaultOrder, 1000)
  }

  const d3NodesContainer = svg.select<SVGGElement>("g.nodes")
  const d3NewNodes = d3NodesContainer.selectAll(".node").each(function () {
    const el = this as Element
    const root = el.getAttribute("data-root") == "true"
    const linkify = widget.getLinkifier()
    if (!root) {
      const type = el.getAttribute("data-type") || ""
      const query = el.getAttribute("data-query") || ""
      NorthData.debug(el.getAttribute("data-url") + " -> " + linkify(el))
      select(el)
        .attr("xlink:href", linkify(el))
        .attr("data-clickable", widget.getClickHandler(type) ? "true" : "false")
        .on("click", function (event: Event) {
          if (widget.invokeClickHandler(query, type)) {
            event.preventDefault()
          }
        })
    }
  })

  d3NodesContainer
    .selectAll(".node")
    .on("mouseover", function () {
      highlight(this as Element)
    })
    .on("mouseout", function () {
      unhighlight(this as Element)
    })

  arrangeInDefaultOrder()

  // REMOVE ME
  NorthData.debug("successfully completed v12")
}
