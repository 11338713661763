import { VERSION } from "./env"
import { registerRenderers } from "./viz/renderer"
import { Widget as WidgetClass } from "./viz/widget"
import { barChart } from "./viz/widgets/barchart"
import { contact } from "./viz/widgets/contacts"
import { dendrogram } from "./viz/widgets/dendrogram"
import { drillDown } from "./viz/widgets/drilldown"
import { financials } from "./viz/widgets/financials"
import { graph } from "./viz/widgets/graph"
import { history } from "./viz/widgets/history"
import { overview } from "./viz/widgets/overview"
import { personTable } from "./viz/widgets/persontable"
import { pubShop } from "./viz/widgets/pubshop"
import { pubTable } from "./viz/widgets/pubtable"
import { searchResults } from "./viz/widgets/searchresults"
import { sheet } from "./viz/widgets/sheet"
import { vita } from "./viz/widgets/vita"

export type ConfigOptions =
  | {
      /** Domain to retrieve widget data */
      dataDomain?: string
      /** Automatically detect data domain from script tags
       * @deprecated */
      detectDataDomain?: boolean
    }
  | undefined

export namespace Bizq {
  export const Widget = WidgetClass
  export const version = VERSION

  export const options = {
    dataDomain: "",
    detectDataDomain: true,
    __debug: true,
  }

  export function initialize(opts?: ConfigOptions) {
    options.__debug = false
    if (opts === undefined) {
      // Set sensible defaults for api users
      options.dataDomain = "https://www.northdata.de"
      options.detectDataDomain = false
    } else {
      options.dataDomain = opts.dataDomain ?? options.dataDomain
      options.detectDataDomain =
        opts.detectDataDomain ?? options.detectDataDomain
    }
  }

  export function debug(...args: unknown[]) {
    log("info", ...args)
  }
  export function log(type: "error" | "info", ...args: unknown[]) {
    if (options.__debug) {
      console[type](...args)
    }
  }

  registerRenderers({
    barChart,
    drillDown,
    graph,
    dendrogram,
    history,
    vita,
    pubTable,
    pubShop,
    personTable,
    sheet,
    financials,
    contact,
    overview,
    searchResults,
  })
}

export const NorthData = Bizq
