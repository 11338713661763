import { select } from "d3-selection"
import { min, max, extent } from "d3-array"
import { scaleLinear } from "d3-scale"
import { cluster, hierarchy, HierarchyPointNode } from "d3-hierarchy"
import { linkHorizontal } from "d3-shape"

import { setHeight } from "../../util/dom"
import {
  bigNodeRadius,
  drawNodes,
  smallNodeRadius,
  createArrowPath,
  Node,
  Link,
} from "../nodes"
import { Renderer, Rendition } from "../renderer"
import { Widget } from "../widget"

interface InputNode {
  root: boolean
  clickable?: boolean
  edge?: { old: boolean; warn: boolean; head?: string; tail?: string }
  id: number
  text: string
  desc: string
  type: string
  icon: string
  query: unknown
}

interface InputRootNode extends InputNode {
  size: number
  scope: string
}

interface GraphNode extends Node {
  depth: number
  edge?: { old: boolean; warn: boolean; head?: string; tail?: string }
}

interface GraphLink extends Link {
  target: GraphNode
  source: GraphNode
}

type HierarchyNode = HierarchyPointNode<InputNode>

export const dendrogram: Renderer = function (
  widget: Widget,
  data: InputRootNode
): Rendition {
  const container = widget.container

  // do not show shake button:
  select(container).selectAll(".shake").style("display", "none")

  const dense = data.size >= 10
  const scope = data.scope
  const svg = select(container)
    .append("svg")
    .attr("data-dense", dense)
    .attr("aria-label", data.desc)

  function draw() {
    const width = container.getBoundingClientRect().width

    const bigBox = {
      left: -width / 6,
      right: width / 6,
      top: -bigNodeRadius,
      bottom: bigNodeRadius + 18 /*text*/,
    }
    const smallBox = {
      left: -smallNodeRadius,
      right: width / 3 - smallNodeRadius,
      top: -smallNodeRadius,
      bottom: smallNodeRadius,
    }
    const leafBox = dense ? smallBox : bigBox
    const nonLeafBox = bigBox

    const bigNodeSize: [number, number] = [
      bigBox.bottom - bigBox.top,
      bigBox.right - bigBox.left,
    ]
    const smallNodeSize: [number, number] = [
      smallBox.bottom - smallBox.top,
      smallBox.right - smallBox.left,
    ]

    const leafNodeSize: [number, number] = dense ? smallNodeSize : bigNodeSize
    const arrowSize = dense ? 7 : 12

    const denseSiblingDistance = 1
    const denseCousinDistance = 1.5
    const minParentDistance = 5

    const clusterLayout = cluster<InputNode>()
      .nodeSize(leafNodeSize)
      .separation(function (a, b) {
        const siblings = a.parent == b.parent
        if (dense) {
          if (siblings) {
            return denseSiblingDistance
          } else {
            const nonAdjustedDistance =
              ((a.parent!.children!.length + b.parent!.children!.length) *
                denseSiblingDistance) /
                2 +
              denseCousinDistance
            const missingDistance = minParentDistance - nonAdjustedDistance
            const adjustedDistance =
              nonAdjustedDistance < minParentDistance
                ? denseCousinDistance + missingDistance
                : denseCousinDistance
            return adjustedDistance
          }
        } else {
          return siblings ? 1.0 : 1.4
        }
      })(hierarchy<InputNode>(data))

    const toNode = (hierarchyNode: HierarchyNode) => ({
      ...hierarchyNode.data,
      x: hierarchyNode.x,
      y: hierarchyNode.y,
      depth: hierarchyNode.depth,
    })
    const nodes: GraphNode[] = clusterLayout.descendants().map(toNode)
    const links: GraphLink[] = clusterLayout.links().map((hierarchyLink) => ({
      target: toNode(hierarchyLink.target),
      source: toNode(hierarchyLink.source),
    }))

    let rootNode
    let maxDepth = 0
    for (let node of nodes) {
      if (node.root) {
        rootNode = node
        if (scope === "publication") {
          node.clickable = true
        }
      }
      maxDepth = Math.max(maxDepth, node.depth)
    }

    svg.select("g").remove()
    const canvas = svg.append("g")
    const drawnLinks = canvas.append("g").selectAll(".link").data(links)

    const edge = (link: GraphLink) => link.target.edge

    const newDrawnLinks = drawnLinks
      .enter()
      .append("g")
      .attr("class", "link")
      .attr("data-source-id", (link) => link.source.id)
      .attr("data-target-id", (link) => link.target.id)
      .attr("data-old", (link) => edge(link)?.old ?? null)
      .attr("data-warn", (link) => edge(link)?.warn ?? null)
      .attr("data-head", (link) => edge(link)?.head ?? null)
      .attr("data-tail", (link) => edge(link)?.tail ?? null)

    newDrawnLinks.append("path").attr("class", "line")

    newDrawnLinks
      .append("path")
      .attr("class", "head")
      .attr("d", createArrowPath(arrowSize))

    newDrawnLinks
      .append("path")
      .attr("class", "tail")
      .attr("d", createArrowPath(arrowSize))

    const drawnNodes = drawNodes(
      widget,
      canvas,
      nodes,
      rootNode ? rootNode.id : undefined,
      dense
    )

    interface Positioner {
      height: number
      canvasTransform: string
      nodeTransform: (node: Node) => string
      diagonal: (link: Link) => string | null
      headTransform: (link: Link) => string
      tailTransform: (link: Link) => string
    }

    const translate = (x: number, y: number) => "translate(" + x + "," + y + ")"

    const createCartesianPositioner = function (width: number): Positioner {
      const isLeaf = (node: Node) =>
        !node.children || node.children.length === 0

      const box = (node: Node) => (isLeaf(node) ? leafBox : nonLeafBox)

      const left = (node: Node) => node.y + box(node).left
      const right = (node: Node) => node.y + box(node).right
      const top = (node: Node) => node.x + box(node).top
      const bottom = (node: Node) => node.x + box(node).bottom

      const hMin = min(nodes, left) as number
      const hMax = max(nodes, right) as number
      const hMinMax = [hMin, hMax]

      const vMin = min(nodes, top) as number
      const vMax = max(nodes, bottom) as number
      const vDomain = extent(nodes, (node: GraphNode) => node.x) as [
        number,
        number,
      ]
      const vExtent = vDomain[1] - vDomain[0]

      const scaleH = scaleLinear().domain(hMinMax).range([0, width])
      const scaleV = scaleLinear().domain(vDomain).range([0, vExtent])

      const positionH = (node: Node) =>
        scaleH(dense && isLeaf(node) ? left(node) : node.y) as number
      const positionV = (node: Node) => scaleV(node.x) as number

      const nodeTransform = (node: Node) =>
        translate(positionH(node), positionV(node))

      const diagonal = linkHorizontal<Link, Node>()
        .x((node) => positionH(node))
        .y((node) => positionV(node))

      const paddingV = Math.max(10, (150 - (vMax - vMin)) / 2)
      const paddingBottom = vMax - vDomain[1] + paddingV
      const paddingTop = vDomain[0] - vMin + paddingV

      const height = vExtent + paddingBottom + paddingTop

      const correctionH = 0
      const correctionV = paddingTop

      return {
        height: height,
        diagonal,
        nodeTransform,
        headTransform: (link) =>
          nodeTransform(link.target) +
          " " +
          translate(dense && isLeaf(link.target) ? 0 : -bigNodeRadius, 0) +
          " rotate(180)",
        tailTransform: (link) =>
          nodeTransform(link.source) + " " + translate(bigNodeRadius, 0),
        canvasTransform: translate(correctionH, correctionV),
      }
    }

    const positioner: Positioner = createCartesianPositioner(width)

    setHeight(container, positioner.height)

    svg
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${width} ${positioner.height}`)

    canvas.attr("transform", positioner.canvasTransform)

    drawnNodes.attr("transform", positioner.nodeTransform)
    newDrawnLinks.select(".line").attr("d", positioner.diagonal)
    newDrawnLinks.select(".head").attr("transform", positioner.headTransform)
    newDrawnLinks.select(".tail").attr("transform", positioner.tailTransform)
  }

  return { draw }
}
