import { select, Selection } from "d3-selection"
import { closest } from "../../util/dom"
import { alreadyDrawn, Renderer, Rendition } from "../renderer"
import { Widget } from "../widget"
import { trilingual } from "../i18n"

type HTMLSelection = Selection<HTMLElement, unknown, null, undefined>
type AnySelection = Selection<any, unknown, any, unknown>

interface Cell {
  value?: number
}

interface Row {
  semantics: string[]
  cell: Cell[]
  level: { value: number }
  info: { childCount: number; id: number; parentId?: number }
  text: string
  name: string
}

interface Currency {
  factor: number
  unit: string
}

interface Column {
  currency: Currency
  date: string
  info: {
    noteHtml: string
    publication: {
      title: string
      date: string
      id: number
    }
    source: string | string[]
  }
}

interface Sheet {
  column: Column[]
  row: Row[]
  info: {
    csvUrl: string
    item: string
  }
}

interface Data {
  sheet: Sheet[]
}

// https://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric
function isNumeric(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export const sheet: Renderer = function (
  widget: Widget,
  data: Data
): Rendition {
  if (!data.sheet) {
    throw new Error("no data")
  }
  for (const sheet of data.sheet) {
    if (!sheet) {
      const subject = widget.stringValue("subject") || "assets" // ???
      throw new Error("no data for subject '" + subject + "'")
    }

    const columns = sheet.column
    const rows = sheet.row

    const reverse = widget.isReverseChronology()
    const maxYears = widget.numberValue("maxYears") || 5
    if (reverse) {
      columns.reverse()
    }
    const shallShowColumn = function (iCol: number) {
      return reverse ? iCol < maxYears : iCol >= columns.length - maxYears
    }

    if (columns.length <= 0) {
      break
    }
    const currency = columns[0].currency
    let factor = 1
    let unit = currency.unit
    let formatter = widget.i18n().numberFormatter

    if (currency) {
      let veryBigCellCount = 0
      let bigCellCount = 0
      let mediumCellCount = 0

      for (let row of rows) {
        for (let selection of row.cell) {
          if (isNumeric(selection.value)) {
            const value = Math.abs(selection.value as number)
            if (value > 1e5) {
              ++mediumCellCount
              if (value > 1e7) {
                ++bigCellCount
                if (value > 1e10) {
                  ++veryBigCellCount
                }
              }
            }
          }
        }
      }

      const qualifyingCellCount = 5
      const veryBigCells = veryBigCellCount >= qualifyingCellCount
      const bigCells = bigCellCount >= qualifyingCellCount
      const smallCells = mediumCellCount < qualifyingCellCount

      if (smallCells) {
        formatter = widget.i18n().decimalFormatter
      }

      if (bigCells) {
        if (unit == "EUR") {
          unit = "€"
        }
        if (unit == "USD") {
          unit = "$"
        }
        if (unit == "GBP") {
          unit = "£"
        }
        const prefix = trilingual("K", "Tsd. ", "K")
        factor = 1e3
        if (veryBigCells) {
          factor = 1e6
          const prefix = trilingual("M", "Mio. ", "M")
        }
        if (prefix.length == 1 && unit.length == 1) {
          unit = " " + unit
        }
        unit = prefix + unit
      }
    }

    const itemNode = document.createElement("table")
    const table = select(itemNode).attr(
      "class",
      "ui bizq very compact celled small unstackable selectable first stuck table"
    )
    const theadRow = table.append("thead").append("tr")
    const theadLeft = theadRow.append("th").attr("class", "first")

    theadLeft.text(unit)
    theadLeft
      .append("a")
      .attr("href", sheet.info.csvUrl)
      .attr("download", "download")
      .attr("title", "CSV/Excel Download")
      .classed("screen-only", true)
      .append("i")
      .attr("class", "download icon")

    columns.forEach((col, iCol) => {
      if (shallShowColumn(iCol)) {
        theadRow
          .append("th")
          .text(col.date ? widget.i18n().dateFormatter(col.date) : "")
          .attr("data-col", columns.length - iCol)
          .attr("data-source-title", col.info.publication.title)
          .attr("data-source-date", col.info.publication.date)
      }
    })

    const hasSemantics = (row: Row, semantic: string) =>
      row && row.semantics && row.semantics.indexOf(semantic) >= 0
    const isSummary = (row: Row) => hasSemantics(row, "SUMMARY")
    const isCustom = (row: Row) => hasSemantics(row, "CUSTOM")

    const tbody = table.append("tbody")

    rows.forEach((row) => {
      if (!hasSemantics(row, "HELPER")) {
        let nonEmptyCells = 0

        const maxLevelVisible = widget.isPrint() ? 2 : 1
        const level = row.level && row.level.value
        let clazz = level < maxLevelVisible ? "expanded" : "closed"
        if (level > maxLevelVisible) {
          clazz += " hidden"
        }

        const tr = select(document.createElement("tr"))
          .attr("class", clazz + " active-on-hover")
          .attr("data-row", row.info && row.info.id)
          .attr("data-level", level)
          .attr("data-parent-row", row.info ? row.info.parentId : (null as any))
          .attr("data-child-count", () =>
            row.info &&
            typeof row.info.childCount == "number" &&
            row.info.childCount > 0
              ? row.info.childCount
              : null
          )
          .attr("data-summary", isSummary(row))
          .attr("data-custom", isCustom(row))
          .on("click", function (event: Event) {
            const node = event && (event.target as HTMLElement)
            toggleChildren(node)
            event.preventDefault()
          })

        tr.append("td")
          .attr("class", "first")
          .text(row.text ?? row.name)

        if (reverse) {
          row.cell.reverse()
        }

        row.cell.forEach((cell, iCol: number) => {
          if (shallShowColumn(iCol)) {
            const text =
              typeof cell.value === "undefined"
                ? ""
                : formatter(cell.value / factor)
            if (text) {
              nonEmptyCells++
            }
            tr.append("td")
              .attr("data-col", columns.length - iCol)
              .text(text)
          }
        })

        if (nonEmptyCells > 0) {
          tbody.node()!.appendChild(tr.node()!)
        } else if (row.info?.parentId) {
          tbody
            .select(`tr[data-row="${row.info.parentId}"][data-child-count]`)
            .attr("data-child-count", function () {
              const newChildCount =
                parseInt(select(this).attr("data-child-count")) - 1
              return newChildCount > 0 ? newChildCount : null
            })
        }
      }
    })

    const tfootRow = table.append("tfoot").append("tr")

    tfootRow
      .append("th")
      .attr("class", "first")
      .text(widget.trilingual("Source", "Quelle", "Source"))

    const clickHandler = widget.handlerValue("sourceClick")
    columns.forEach((col, iCol) => {
      if (shallShowColumn(iCol)) {
        const th = tfootRow.append("th").attr("data-col", columns.length - iCol)
        th.html(col.info.noteHtml)

        if (clickHandler) {
          const publication = col.info.publication
          const source =
            typeof col.info.source === "string"
              ? [col.info.source]
              : col.info.source

          th.selectAll("a")
            .attr("href", "javascript:void(0);")
            .on("click", function (event: Event) {
              event.preventDefault()
              clickHandler({ publication, source })
            })
        }
      }
    })

    itemNode.setAttribute("data-item", sheet.info.item)
    widget.showItem(undefined, itemNode)
  }

  // mouse interactions
  const expanded = "expanded"
  const closed = "closed"
  function rowOf(node: HTMLElement): HTMLSelection {
    const d3node = select(node)
    return node.matches("tr") ? d3node : closest(d3node, "tr")
  }
  function children(row: HTMLSelection): AnySelection {
    let id = row.size() == 0 ? "?" : row.attr("data-row")
    return closest(row, "table").selectAll("[data-parent-row='" + id + "']")
  }
  function showChildren(node: HTMLElement) {
    const row = rowOf(node)
    row.classed(closed, false).classed(expanded, true)
    children(row).classed("hidden", false)
  }
  function hideChildren(node: HTMLElement) {
    const row = rowOf(node)
    row.classed(closed, true).classed(expanded, false)
    children(row)
      .classed("hidden", true)
      .each(function () {
        hideChildren(this)
      })
  }
  function toggleChildren(node: HTMLElement) {
    const row = rowOf(node)
    if (row.classed(expanded)) {
      hideChildren(node)
    } else {
      showChildren(node)
    }
  }

  return alreadyDrawn
}
