import { select } from "d3-selection"
import { Data } from "../base"
import { Renderer, Rendition, alreadyDrawn } from "../renderer"
import { Widget } from "../widget"

export const financials: Renderer = function (
  widget: Widget,
  data: Data
): Rendition {
  const history =
    data &&
    data.financials &&
    data.financials.filter(
      (financial: { items: unknown[] }) => financial.items.length > 0
    )
  const reverse = widget.isReverseChronology()

  if (history && history.length > 0 && history[0].items.length > 0) {
    const table = select(widget.container)
      .append("table")
      .attr(
        "class",
        "ui bizq very compact celled small unstackable striped selectable first stuck table"
      )
    const theadRow = table.append("thead").append("tr")
    const tbody = table.append("tbody")
    const tfootRow = table.append("tfoot").append("tr")
    var sampleItemsById: { [key: string]: any } = {}
    for (const financials of history) {
      financials.itemsById = {}
      for (const item of financials.items) {
        financials.itemsById[item.id] = item
        sampleItemsById[item.id] = item
      }
    }
    const latestFinancials = history[history.length - 1]
    if (reverse) {
      history.reverse()
    }

    const theadLeft = theadRow
      .append("th")
      .attr("class", "first")
      .text(widget.stringValue("title") as string)
    theadLeft
      .append("a")
      .attr("href", data.csvUrl)
      .attr("download", "download")
      .attr("title", "CSV/Excel Download")
      .classed("screen-only", true)
      .append("i")
      .attr("class", "download icon")

    for (const financials of history) {
      theadRow.append("th").text(financials.formattedDate)
    }
    for (const id in sampleItemsById) {
      const tbodyRow = tbody.append("tr").attr("class", "active-on-hover")
      const rowItem = sampleItemsById[id]
      tbodyRow.append("td").text(rowItem["name"])
      for (const financials of history) {
        const cellItem = financials.itemsById[id]
        const td = tbodyRow.append("td")
        if (cellItem) {
          const signClass = cellItem.value >= 0 ? "pos" : "neg"
          let text = cellItem.formattedValue
          td.append("span")
            .attr("class", signClass + " value")
            .text(text)
          if (cellItem.estimate) {
            td.append("a")
              .attr("href", "javascript:void()")
              .attr(
                "title",
                widget.trilingual(
                  "Unsafe value (estimation or indirect determination)",
                  "Indirekte oder unsichere Bestimmungsmethode",
                  "Valeur non sûre (estimation ou détermination indirecte)"
                )
              )
              .html(" *&nbsp;")
          }
          if (cellItem.note) {
            td.append("span").attr("class", "note").text(cellItem.note)
          }
        } else {
          td.append("span").text("N/A")
        }
      }
    }

    if (latestFinancials.source) {
      if (latestFinancials.source.auditorName) {
        const tbodyRow = tbody.append("tr").attr("class", "active-on-hover")
        tbodyRow
          .append("td")
          .text(widget.trilingual("Auditor", "Prüfer", "Auditeur"))
        for (const financials of history) {
          const auditorName = financials.source && financials.source.auditorName
          const td = tbodyRow.append("td")
          td.append("span")
            .attr("class", "auditor value")
            .text(auditorName || "N/A")
        }
      }
      if (latestFinancials.source && latestFinancials.source.publicationDate) {
        const tbodyRow = tbody.append("tr").attr("class", "active-on-hover")
        tbodyRow
          .append("td")
          .text(
            widget.trilingual(
              "Publication date",
              "Veröffentlichungs\u00ADdatum",
              "Date de publication"
            )
          )
        for (const financials of history) {
          const date = financials.source && financials.source.publicationDate
          const td = tbodyRow.append("td")
          td.append("span")
            .attr("class", "date value")
            .text(date ? widget.i18n().dateFormatter(new Date(date)) : "N/A")
        }
      }
      tfootRow
        .append("th")
        .text(widget.trilingual("Source", "Quelle", "Source"))

      const clickHandler = widget.handlerValue("sourceClick")
      for (const financials of history) {
        const th = tfootRow.append("th")
        th.html(financials.note.html)

        if (clickHandler) {
          th.selectAll("a")
            .attr("href", "javascript:void(0);")
            .on("click", function (event: Event) {
              event.preventDefault()
              clickHandler(financials.source)
            })
        }
      }
    }
  } else {
    throw new Error("no data available")
  }
  return alreadyDrawn
}
