import { select, selectAll, Selection } from "d3-selection"
import { HSLColor } from "d3-color"
import { treemap, hierarchy, HierarchyRectangularNode } from "d3-hierarchy"
import { ascending } from "d3-array"
import { size } from "../../util/dom"
import { Renderer, Rendition } from "../renderer"
import { Widget } from "../widget"
import { trilingual } from "../i18n"

interface Box {
  name: string
  raw: number
  formattedValue?: string
  color: string
  children?: Box[]
}

interface Data {
  numberFormat: {
    prefix: string
    accuracy: number
    postfix: string
  }
  default: Box
  root: Box
}

interface Item {
  data: Data
  item: string
  title: string
  date: string
}

interface Data {
  item: Item[]
}

type AnySelection = Selection<any, unknown, any, unknown>

export const drillDown: Renderer = function (
  widget: Widget,
  allData: Data
): Rendition {
  if (!allData.item || allData.item.length == 0) {
    throw new Error("no data")
  }
  const drawables: (() => void)[] = []
  for (const detail of allData.item) {
    const data = detail.data
    const itemNode = document.createElement("div")
    const root = select(itemNode)
      .attr("data-item", detail.item)
      .attr("class", "root")
    const boxParent = root.append("div").attr("class", "box-parent")

    widget.showItem(detail.title, itemNode)

    let legend1: AnySelection = select(widget.nodeValue("legend1") ?? null)
    if (legend1.empty()) {
      legend1 = root.selectAll(".legend1")
    }
    let legend2: AnySelection = select(widget.nodeValue("legend2") ?? null)
    if (legend2.empty()) {
      legend2 = root.selectAll(".legend2")
    }
    let legend3: AnySelection = select(widget.nodeValue("legend3") ?? null)
    if (legend3.empty()) {
      legend3 = root.selectAll(".legend3")
    }

    if (legend1.empty() || legend2.empty() || legend3.empty()) {
      let legendParent: AnySelection = root.selectAll(".legends")
      if (legendParent.empty()) {
        legendParent = root.append("div").attr("class", "legends")
      }
      if (legend1.empty()) {
        legend1 = legendParent.append("span").attr("class", "legend1")
      }
      if (legend2.empty()) {
        legend2 = legendParent.append("span").attr("class", "legend2")
      }
      if (legend3.empty()) {
        legend3 = legendParent.append("span").attr("class", "legend3")
      }
    }

    legend3.text(trilingual("As of: ", "Stand: ", "Date de ") + detail.date)

    const numberFormatter = widget
      .i18n()
      .createNumberFormatter(data.numberFormat, ",.3r")
    let fixedHighlightedbox: Box | null = null
    const highlight = function (element: HTMLElement, data: Box) {
      if (data.children) {
        return
      }
      if (!fixedHighlightedbox || fixedHighlightedbox == data) {
        boxParent.classed("highlighting", true)
        selectAll(".highlighted").classed("highlighted", false)
        select(element).classed("highlighted", true)
        showLegend(data)
      }
    }
    const unhighlight = function () {
      if (!fixedHighlightedbox) {
        boxParent.classed("highlighting", false)
        selectAll(".highlighted").classed("highlighted", false)
        showLegend(data.default ?? data.root)
      }
    }
    const fixHighlight = function (element: HTMLElement, data: Box) {
      if (fixedHighlightedbox == data) {
        fixedHighlightedbox = null
        unhighlight()
      } else {
        fixedHighlightedbox = data
        highlight(element, data)
      }
    }
    const containsNeg = function (text: string): boolean {
      return !!text && text.indexOf("Fehlbetrag") >= 0
    }
    const showLegend = function (box: Box) {
      legend1.text((box.formattedValue ?? numberFormatter(box.raw)) + " ")
      legend2.text(box.name)
    }

    const assignColors = function (node: Box) {
      function _assignColors(
        node: Box,
        color: HSLColor,
        minHue: number,
        maxHue: number
      ) {
        node.color = color.toString()
        let hue = minHue
        if (node.children) {
          const nChildren = node.children.length + 1
          if (nChildren > 0) {
            const dHue = (maxHue - minHue) / nChildren
            for (let child of node.children) {
              color.h = hue % 360
              _assignColors(child, color, hue, hue + dHue)
              hue += dHue
            }
          }
        }
      }
      const rootColor = widget.getRootColor()
      _assignColors(node, rootColor, rootColor.h, rootColor.h + 360)
    }
    unhighlight()
    assignColors(data.root)
    const draw = function () {
      let rect = boxParent.node()!.getBoundingClientRect()
      if (rect.width == 0 || rect.height == 0) {
        return
      }

      const boxHierarchy = hierarchy(data.root)
        .sum((box) => (box.children ? 0 : Math.abs(box.raw)))
        .sort((a, b) => ascending(a.value, b.value))

      const treemapLayout = treemap<Box>().size(size(rect)).round(true)(
        boxHierarchy
      )

      const dx = (node: HierarchyRectangularNode<Box>) => node.x1 - node.x0
      const dy = (node: HierarchyRectangularNode<Box>) => node.y1 - node.y0

      const boxes = boxParent
        .selectAll<HTMLDivElement, unknown>(".box")
        .data(treemapLayout.descendants())
      const newBoxes = boxes
        .enter()
        .append("div")
        .attr("class", "box")
        .on("mouseenter", function (_event, node) {
          highlight(this, node.data)
        })
        .on("mouseleave", unhighlight)
        .on("click", function (_event, node) {
          fixHighlight(this, node.data)
        })
        .attr("data-neg", (node) =>
          node.data.raw < 0 || containsNeg(node.data.name) ? true : false
        )
      newBoxes
        .append("div")
        .attr("class", "value")
        .text((node) =>
          node.children
            ? null
            : (node?.data?.formattedValue ??
              numberFormatter(node.value as number))
        )
      newBoxes
        .append("div")
        .attr("class", "desc")
        .text((node) => (node.children ? null : node.data.name))
      newBoxes
        .merge(boxes) // enter + update
        .attr("data-small", (node) =>
          dx(node) < 40 || dy(node) < 32 ? true : null
        )
        .style("left", (node) => node.x0 + "px")
        .style("top", (node) => node.y0 + "px")
        .style("width", (node) => Math.max(0, dx(node) - 1) + "px")
        .style("height", (node) => Math.max(0, dy(node) - 1) + "px")
        .style("background", (node) =>
          node.children ? "white" : node.data.color
        )
    }
    drawables.push(draw)
  }
  return {
    draw: function () {
      for (const drawable of drawables) {
        drawable()
      }
    },
  }
}
