import { BaseType, Selection } from "d3-selection"
import { hsl, HSLColor } from "d3-color"
import { select } from "d3-selection"

import { Rendition, getRegisteredRenderer } from "./renderer"
import { Handler, Linkifier } from "./base"
import { setHeight } from "../util/dom"
import { camelCased } from "../util/string"
import { dataSource, RequestOptions } from "./datasource"
import { I18n, German, English, French } from "./i18n"
import { NorthData } from "../viz-base"

interface WidgetOptions extends RequestOptions {
  type?: string
  colors?: string | string[]
  [key: string]: unknown
}

export class Widget {
  data: {}
  chart?: Rendition
  readonly container: HTMLElement
  readonly widgetType: string
  readonly options: WidgetOptions
  readonly items: HTMLElement[] = []

  constructor(container: HTMLElement | string, options: WidgetOptions) {
    const containerNode =
      typeof container == "string"
        ? document.getElementById(container)
        : container
    if (!containerNode) {
      throw new Error("container node undefined or null")
    }
    this.container = containerNode
    this.options = mixinDataAttributes(options, this.container)
    const widgetType = this.stringValue("type") || this.stringValue("layout")
    if (!widgetType) {
      throw new Error("no widget type specified")
    }
    this.widgetType = widgetType
    this.options["type"] = widgetType
    if (widgetType == "graph" || widgetType == "contact") {
      // don't bundle heavy calls with others
      this.options["immediate"] = true
    }
    const loadHandler = this.load.bind(this)
    this.data = this.objectValue("data") as object
    // if data is available, continue with load handler
    if (this.data) {
      window.setTimeout(loadHandler)
    }
    // if data is not yet available, request it from the server
    else {
      dataSource.requestData(this.options, loadHandler, this.fail.bind(this))
    }
  }

  getType() {
    return this.widgetType
  }

  fail(error: unknown) {
    const onError = this.handlerValue("error")
    if (onError) {
      onError(error)
    } else {
      NorthData.log("error", error)
    }
  }

  load(data: any) {
    this.data = this.data || data
    try {
      const renderer = getRegisteredRenderer(this.widgetType)
      if (!renderer) {
        throw new Error("invalid widget type: " + this.widgetType)
      }
      this.chart = renderer(this, this.data)
      this.container.setAttribute("data-layout", this.widgetType)
      this.draw()
    } catch (error) {
      this.fail(error)
      return
    }
    const onSuccess = this.handlerValue("success")
    if (onSuccess) {
      onSuccess()
    }
  }

  showItem(title: string | undefined, itemNode: HTMLElement) {
    const defaultItemHandler = (title: string, element: HTMLElement) => {
      const legacyBehavior = !!this.stringValue("subject")
      if (legacyBehavior) {
        return element
      }
      const result = document.createElement("div")
      const titleEl = document.createElement("h2")
      titleEl.textContent = title
      result.appendChild(titleEl)
      result.appendChild(element)
      return result
    }
    const itemHandler = this.handlerValue("handleItem") || defaultItemHandler
    const handledItem = itemHandler(title as string, itemNode) as HTMLElement
    if (handledItem) {
      this.container.appendChild(handledItem)
      this.items.push(handledItem)
    }
  }

  adjustSize(element: HTMLElement) {
    const rect = element.getBoundingClientRect()
    const width = this.numberValue("width") || rect.width
    const height = this.numberValue("height") || rect.height
    const ratio = this.numberValue("ratio")
    const minHeight = this.numberValue("minHeight")
    const maxHeight = this.numberValue("maxHeight")
    let newHeight = height
    if (ratio) {
      newHeight = width * ratio
    }
    if (minHeight) {
      newHeight = Math.max(newHeight, minHeight)
    }
    if (maxHeight) {
      newHeight = Math.min(newHeight, maxHeight)
    }
    if (height != newHeight) {
      setHeight(element, newHeight)
    }
  }

  draw() {
    this.adjustSize(this.container)
    if (this.chart) {
      this.chart.draw()
      select(this.container)
        .selectAll<HTMLElement, unknown>("*")
        .each(function () {
          // IE <= 11 compatibility
          let transform = this.getAttribute("transform")
          if (!transform) {
            transform = getComputedStyle(this).getPropertyValue("transform")
            if (transform && transform != "none") {
              this.setAttribute("transform", transform)
            }
          }
        })
    }
  }

  markup<GElement extends BaseType, Datum, PElement extends BaseType>(
    html: Selection<GElement, Datum, PElement, unknown>
  ) {
    const companyClick = this.handlerValue("companyClick")
    if (companyClick) {
      html.selectAll(".company").attr("data-clickable", true)
    }
    const personClick = this.handlerValue("personClick")
    if (personClick) {
      html.selectAll(".person").attr("data-clickable", true)
    }
    const addressClick = this.handlerValue("addressClick")
    if (addressClick) {
      html.selectAll("address").attr("data-clickable", true)
    }
    const registerClick = this.handlerValue("registerClick")
    if (registerClick) {
      html.selectAll(".register").attr("data-clickable", true)
    }
    const filingClick = this.handlerValue("filingClick")
    if (filingClick) {
      html.selectAll(".filing").attr("data-clickable", true)
    }

    html.on("click", function (event: Event) {
      const target = event.target as HTMLElement
      const data = dataAttributesToObject(target)
      let handled
      if (companyClick && target.classList.contains("company")) {
        companyClick(data)
        handled = true
      }
      if (personClick && target.classList.contains("person")) {
        personClick(data)
        handled = true
      }
      if (addressClick && target.tagName == "ADDRESS") {
        addressClick({ query: target.textContent })
        handled = true
      }
      if (registerClick && target.classList.contains("register")) {
        registerClick({ query: target.textContent })
        handled = true
      }
      if (filingClick && target.classList.contains("filing")) {
        filingClick({ query: target.textContent })
        handled = true
      }
      if (handled) {
        event.preventDefault()
      }
    })
  }

  invokeClickHandler(query: unknown, dataType?: string): boolean {
    const handler = this.getClickHandler(dataType)
    if (handler) {
      handler(query)
      return true
    }
    return false
  }

  getClickHandler(dataType?: string): Handler | undefined {
    if (dataType) {
      switch (dataType.toString().toLowerCase()[0]) {
        case "c":
          return this.handlerValue("companyClick")
        case "p":
          return this.handlerValue("personClick")
        case "h":
          return this.handlerValue("publicationClick")
      }
    }
  }

  getLinkifier(): Linkifier {
    return (this.handlerValue("linkify") as Linkifier) || defaultLinkify
  }

  getRootColor(): HSLColor {
    return hsl(this.stringValue("rootColor") || "#00dddd")
  }

  isReverseChronology(): boolean {
    return this.stringValue("chronology") === "reverse"
  }

  getLanguage(): string {
    return this.stringValue("language") || "de"
  }

  i18n(): I18n {
    return this.getLanguage() == "de"
      ? German
      : this.getLanguage() == "fr"
        ? French
        : English
  }

  trilingual(english: string, german: string, french: string): string {
    return this.getLanguage() == "de"
      ? german
      : this.getLanguage() == "fr"
        ? french
        : english
  }

  isPrint() {
    return (
      document.documentElement.classList &&
      document.documentElement.classList.contains("print")
    )
  }

  numberValue(key: string): number | undefined {
    const value = this.options[key]
    if (typeof value === "function") {
      return value() as number
    }
    if (typeof value === "number") {
      return value
    }
    if (typeof value === "string") {
      return Number(value)
    }
  }

  stringValue(key: string): string | undefined {
    const value = this.options[key]
    if (typeof value === "function") {
      return value() as string
    }
    if (typeof value === "string") {
      return value
    }
  }

  nodeValue(key: string): HTMLElement | undefined {
    let value = this.options[key]
    if (typeof value === "object") {
      return value as HTMLElement
    }
    if (typeof value === "function") {
      return value() as HTMLElement
    }
    if (typeof value === "string") {
      return select(this.container)
        .select<HTMLElement>(value)
        .node() as HTMLElement
    }
  }

  objectValue(key: string): string | object | undefined {
    const value = this.options[key]
    if (typeof value === "function") {
      return value() as string
    }
    if (typeof value === "object") {
      if (value === null) {
        return undefined
      }
      return value
    }
    if (typeof value === "string") {
      // parse json
      return JSON.parse(value)
    }
  }

  handlerValue(key: string): Handler | undefined {
    const value = this.options[key]
    if (typeof value === "function") {
      return value.bind(this)
    }
  }
}

function mixinDataAttributes(
  options: WidgetOptions,
  container: HTMLElement
): WidgetOptions {
  const result: Record<string, any> = {}
  Object["assign"](result, options)
  for (let i = 0; i < container.attributes.length; ++i) {
    const attribute = container.attributes[i]
    const name = attribute.name
    if (name.indexOf("data-") === 0) {
      const key = camelCased(name.substring(5))
      result[key] = result[key] || attribute.value
    }
  }
  return result
}

function dataAttributesToObject(element: HTMLElement): {} {
  return mixinDataAttributes({}, element)
}

const defaultLinkify = () => null
