import { NorthData } from "../viz-base"
import { Callback, getDataDomain } from "./base"
import { json } from "d3-fetch"

export type RequestOptions = {
  apiKey?: string
  immediate?: boolean
}

type Request = {
  options: RequestOptions
  success: Callback
  error: Callback
}

interface DataSource {
  requestData(options: RequestOptions, success: Callback, error: Callback): void
}

export const dataSource: DataSource = (function () {
  let waitingRequests: Request[] = []

  function requestData(
    options: RequestOptions,
    success: Callback,
    error: Callback
  ): void {
    const request = { options, success, error }
    if (options["immediate"]) {
      executeRequests([request])
    } else {
      waitingRequests.push(request)
      window.setTimeout(executeWaitingRequests)
    }
  }

  function submit(
    apiKey: string | undefined,
    params: Record<string, any>,
    callback: (err: any, data: any) => void
  ) {
    const detectedDomain = NorthData.options.detectDataDomain
      ? getDataDomain()
      : undefined
    const domain = detectedDomain || NorthData.options.dataDomain
    const baseUrl =
      ((window as any)["dataDomain"] /* undocumented: for CORS testing!*/ ||
        domain) + "/data.json"
    const headers = {
      "Content-Type": "application/json; charset=UTF-8",
      ...(apiKey && { "X-Api-Key": apiKey }),
    }

    json(baseUrl, {
      headers,
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((data) => callback(null, data))
      .catch((error) => callback(error, undefined))
  }

  function executeRequests(requests: Request[]) {
    const options: RequestOptions[] = []
    const params: Record<string, any> = {}
    let apiKey: string | undefined = undefined
    for (const request of requests) {
      options.push(request.options)
      apiKey = apiKey ?? request.options["apiKey"]
    }
    if (options.length > 0) {
      params["options"] = options
      params["origin"] = document.location.hostname
      params["v"] = NorthData.version
      submit(apiKey, params, function (error: Error, response) {
        if (error) {
          for (let request of requests) {
            request.error(error.message)
          }
        } else {
          const results = response["result"]
          requests.forEach((request, index) => {
            const result = results[index]
            const error = result.error
            if (error) {
              request.error(error.message)
            } else {
              request.success(results[index])
            }
          })
        }
      })
    }
  }

  function executeWaitingRequests() {
    executeRequests(waitingRequests)
    waitingRequests = []
  }

  return { requestData }
})()
