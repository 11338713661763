import { Data } from "../base"
import { alreadyDrawn, Renderer, Rendition } from "../renderer"
import { Widget } from "../widget"
import * as Ui from "../../util/ui"
import * as Dom from "../../util/dom"
import { NorthData } from "../../viz-base"

export const pubShop: Renderer = function (
  widget: Widget,
  data: Data
): Rendition {
  const onClick = function () {
    Ui.setWaiting(true)
    const request = new XMLHttpRequest()
    request.open("POST", "/_api/shop/v1/products")
    request.setRequestHeader("Content-Type", "application/json")
    function onError(error: any) {
      NorthData.log("error", "cannot list pubs", error)
    }
    request.onerror = function (error) {
      Ui.setWaiting(false)
      onError(error)
    }
    request.onload = function () {
      Ui.setWaiting(false)
      NorthData.debug(request.status)
      if (request.status >= 400) {
        onError(request.responseText || request.statusText)
      }
      NorthData.debug("ok", request.responseText)
      const data = JSON.parse(request.responseText)
      NorthData.debug("data", data)
    }
    request.send(JSON.stringify(widget.options))
    return false
  }

  Dom.onEvent("click", widget.container, "*", onClick)

  return alreadyDrawn
}
